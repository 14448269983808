import { CreateView } from "../createView";
import useChoices from "../../hooks/useChoices";
import { CommonForm } from "./CommonForm";

export const SubcategoryForm = () => {
  const categoryChoices = useChoices('category');

  return (
    <CreateView resource={"SubCategory"}>
      <CommonForm
        resource="Subcategory"
        fields={["category", "name", "nameAr", "isActive", "image"]}
        includeSimpleForm={true}
        choices={{ category: categoryChoices }}
      />
    </CreateView>
  );
};
