import NumberFormatter from "../utils/numberFormatterUtils";
import mainDataProviderClient, {
    RequestTypes,
} from "./common/mainDataProviderClient";

const statisticsDataProvider = {
    getStatistics: async (resource, params) => {
        try {

            const response = await mainDataProviderClient(
                `${resource}`,
                RequestTypes.getById,
                {
                    method: "POST",
                    body: JSON.stringify({
                        "dateFrom": new Date(new Date().getFullYear(), 0, 1),
                        "dateTo": new Date()
                    }),
                }
            );

            return {
                data: {
                    totalSales: NumberFormatter.instance.formatNumber(
                        {
                            style: "currency",
                            currency: "AED",
                            maximumFractionDigits: 2,
                            trailingZeroDisplay: "stripIfInteger",
                        },
                        response.data?.totalSales?.totalSales ?? 0
                    ),
                    totalOrders: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, response.data?.totalOrders ?? 0),
                    totalUsers: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, response.data?.totalUsers ?? 0)
                },
            };
        } catch (error) {
            throw new Error(error.message ?? "Failed to get statistics");
        }
    },
};
export default statisticsDataProvider;
