import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";

const countryDataProvider = {
  getList: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}?limit=100`,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((country) => ({
        id: country.id,
        name: country.country,
      })),
      total: response.total,
    };
  },

  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        name: response.data.country,
      },
    };
  },
};

export default countryDataProvider;
