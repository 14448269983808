import { Edit, SimpleForm, TabbedShowLayout } from "react-admin";
import { CommonEdit } from "./CommonEdit";

export const BrandEdit = () => (
  <Edit>
    <SimpleForm>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <CommonEdit fields={["name", "nameAr", "image"]} />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
);
