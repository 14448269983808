import { Grid } from "@mui/material";
import { ArrayField, Datagrid, TextField, ImageField, Button } from "react-admin";
import { CSVLink } from 'react-csv';
import orderDataProvider from "../../dataProviders/orderDataProvider";
import { useEffect, useState } from "react";

const UserOrdersView = ({ record }) => {
    const [csvData, setCsvData] = useState([]);

    useEffect(() => {
        const exportOrderAsCSV = async () => {
            try {
                const orderData = await orderDataProvider.getOne('order', { id: record.id });

                const orderInfo = orderData.data;
                const productsFormatted = orderInfo.products.map(prod => `${prod.name}\nQuantity: ${prod.quantity}\nPrice: ${prod.price}`).join('\n\n');
                const addressFormatted = `Address Title: ${orderInfo.address.addressTitle}\nAddress: ${orderInfo.address.address}\nStreet: ${orderInfo.address.street}\nNearest Land: ${orderInfo.address.nearestLand}\nContact Number: ${orderInfo.address.contactNumber}`;


                const csvReport = {
                    filename: 'user_orders.csv',
                    data: [
                        {
                            'Invoice_Number': orderInfo.invoiceNumber,
                            'Total_Amount': orderInfo.totalAmount,
                            'Scheduled_Date': orderInfo.scheduledDate,
                            'Scheduled_Time': orderInfo.scheduledTime,
                            'Customer_Name': orderInfo.customerName,
                            'Products': productsFormatted,
                            'Address': addressFormatted,

                        }
                    ],
                };



                setCsvData(csvReport.data);

            } catch (error) {
                console.error('Error exporting orders:', error);
            }
        }
        exportOrderAsCSV();
    }, [])

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
                <CSVLink data={csvData} filename="user_orders.csv">
                    Export User Orders as CSV
                </CSVLink>
                <ArrayField source="products" record={record}>
                    <Datagrid className="address-grid-field" bulkActionButtons={false}>
                        <ImageField source="image" />
                        <TextField source="name" />
                        <TextField source="country" />
                        <TextField source="price" />
                        <TextField source="quantity" />
                    </Datagrid>
                </ArrayField>
            </Grid>
        </Grid>
    );
};

export default UserOrdersView;