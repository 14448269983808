export function createUrl(resource, params) {
    let url = resource;
    if (params) {
        if (params.filter?.name) {
            url += `/get-by-search?limit=${params.pagination.perPage}&page=${params.pagination.page}&searchQuery=${params.filter.name}`
        } else {
            url += `?limit=${params.pagination.perPage}&page=${params.pagination.page}`
        }
    } else {
        url += `?limit=1000&page=1`
    }
    return url;
}