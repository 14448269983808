import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
    RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const tagDataProvider = {
    deleteMany: async (resource, params) => {
        let body = {
            tagIds: params.ids,
        }

        try {
            await mainDataProviderClient(
                `${resource}/delete-tags`,
                RequestTypes.update,
                {
                    method: "PATCH",
                    body: JSON.stringify(body),
                }
            );

            return { data: [] };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update brand");
        }
    },
    create: async (resource, params) => {
        let formData = new FormData();
        formData.append("name", params.data["name"]);
        formData.append("nameAr", params.data["nameAr"]);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}`,
                RequestTypes.create,
                {
                    method: "POST",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? `Failed to create ${resource}`);
        }
    },
    getList: async (resource, params) => {
        const url = createUrl(resource, params);

        const response = await mainDataProviderClient(
            url,
            RequestTypes.getAll
        );

        return {
            data: response.data.map((item) => ({
                id: item.id,
                name: item.name,
                nameAr: item.nameAr,
                image: item.imageUrl,
                createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
                updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
            })),
            total: response.total,
        };
    },

    getOne: async (resource, params) => {
        const response = await mainDataProviderClient(
            `${resource}/${params.id}`,
            RequestTypes.getById
        );

        return {
            data: {
                ...response.data,
                id: params.id,
                createdAt: DateFormatter.instance.formatDateUTC(response.data.createdAt),
                updatedAt: DateFormatter.instance.formatDateUTC(response.data.updatedAt),
                name: response.data.name,
                nameAr: response.data.nameAr,
                image: response.data.imageUrl
            },
        };
    },

    update: async (resource, params) => {
        let formData = new FormData();
        formData.append("name", params.data["name"]);
        formData.append("nameAr", params.data["nameAr"]);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}/${params.id}`,
                RequestTypes.update,
                {
                    method: "PATCH",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Tag");
        }
    },
};
export default tagDataProvider;
