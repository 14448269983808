import { List, Pagination, TextInput, useListContext } from "react-admin";
import { CommonList } from "./CommonList";
import EditStatusPopup from "../EditStatusPopup";
import { useStatusPopup } from "../../utils/editStatus";
import { useParams } from "react-router-dom";

const filters = [
  <TextInput label="Title" source="name" defaultValue="" />,
];
const PostPagination = ({ total }) => <Pagination total={total} rowsPerPageOptions={[10, 25, 50, 100]} />;
export const OrderList = (props) => {
  const { total } = useListContext();
  const { open, handleOpenEditStatus, handleSaveStatus, handleClosePopup } = useStatusPopup();
  const orderId = useParams();



  return (
    <List filters={filters} {...props} pagination={<PostPagination total={total} />} title="Orders List">
      <CommonList fields={[
        "invoiceNumber",
        "customerName",
        "dueDate",
        "dueTime",
        "total",
        "status",
        "editStatusButton",

      ]}
        includeBulkActions={false}
        handleOpenStatusPopup={() => handleOpenEditStatus(orderId)}
      />
      {open && (
        <EditStatusPopup
          title={"Change Status"}
          statuses={['Confirmed', 'Processing', 'Out for Delivery', 'Delivered', 'Closed' , 'Cancel']}
          onSave={(newStatus) => handleSaveStatus(orderId, newStatus)}
          onClose={handleClosePopup}
          open={open}
        />
      )}
    </List>
  )
};
