import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";
import NumberFormatter from "../utils/numberFormatterUtils";

const userDataProvider = {
  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(
      url,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((user) => ({
        id: user.id,
        name: user.info.general.name,
        email: user.info.general.email,
        createdAt: DateFormatter.instance.formatDateUTC(user.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(user.updatedAt),
        active: user.active,
        accountVerification: user.info.general.accountVerification,
        role: user.info.general.role.name,
        phoneNumber: user.info.general.phoneNumber,
        imageUrl: user.info.general.imageUrl,
      })),
      total: response.total,
    };
  },
  update: async (resource, params) => {

    let data = {
      name: params.data.userInfo.name,
      active: params.data.userInfo.active
    }

    try {
      const response = await mainDataProviderClient(
        `${resource}/admin-update-user/${params.id}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(data),
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update brand");
    }
  },
  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/get-user-with-order/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        userInfo: {
          name: response.data.userInfo.info.general.name,
          email: response.data.userInfo.info.general.email,
          createdAt: DateFormatter.instance.formatDateUTC(response.data.userInfo.createdAt),
          updatedAt: DateFormatter.instance.formatDateUTC(response.data.userInfo.updatedAt),
          active: response.data.userInfo.active,
          accountVerification: response.data.userInfo.info.general.accountVerification,
          role: response.data.userInfo.info.general.role.name,
          phoneNumber: response.data.userInfo.info.general.phoneNumber,
          imageUrl: response.data.userInfo.info.general.imageUrl,
        },
        addresses: response.data.addresses.map((item) => {
          return {
            address: item.address,
            addressTitle: item.addressTitle,
            contactNumber: item.contactNumber,
            createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
            updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
            id: item.id,
            isDefaultAddress: item.isDefaultAddress,
            latitude: item.latitude ?? 0,
            longitude: item.longitude ?? 0,
            nearestLand: item.nearestLand,
            street: item.street
          }
        }),
        orders: response.data.orders.map((item) => {
          return {
            createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
            updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
            address: {
              id: item.address.id,
              longitude: item.address.longitude,
              latitude: item.address.latitude
            },
            id: item.id,
            invoiceNumber: item.keyOrder ?? "",
            products: item.products.map((prod) => {
              return {
                id: prod.id,
                image: prod.imageUrls.length > 0 ? prod.imageUrls[0].imageUrl : null,
                name: prod.name,
                quantity: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, prod.quantity),
                price: NumberFormatter.instance.formatNumber(
                  {
                    style: "currency",
                    currency: "AED",
                    maximumFractionDigits: 2,
                    trailingZeroDisplay: "stripIfInteger",
                  },
                  prod.price
                ),
                country: prod.country.country,
                isActive: prod.isActive
              }
            }),
            scheduledDate: item.scheduledDate,
            scheduledTime: item.scheduledTime,
            totalAmount: NumberFormatter.instance.formatNumber(
              {
                style: "currency",
                currency: "AED",
                maximumFractionDigits: 2,
                trailingZeroDisplay: "stripIfInteger",
              },
              item.totalAmount
            )
          }
        })
      },
    };
  },
};

export default userDataProvider;
