import { Edit, SimpleForm, TabbedShowLayout } from "react-admin";
import { CommonEdit } from "./CommonEdit";

export const UserEdit = () => (
  <Edit>
    <SimpleForm>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <CommonEdit
            fields={["userInfo.name", "userInfo.email", "userInfo.active"]}
          />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </SimpleForm>
  </Edit>
);
