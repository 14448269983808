import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const BannerView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={["image", "bannerTypes.name", "priority", "actionUrl", "createdAt", "updatedAt"]}
          isView={true}
          labels={["Image", "Banner Type", "priority", "Action Url", "Created At", "Updated At"]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
