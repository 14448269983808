import { Grid } from "@mui/material";
import React from "react";
import { ChipField, Labeled } from "react-admin";

const UserAddressView = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <Labeled title="Address">
                    <ChipField source="address" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Contact Number">
                    <ChipField source="contactNumber" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Nearest Land">
                    <ChipField source="nearestLand" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Street">
                    <ChipField source="street" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Latitude">
                    <ChipField source="latitude" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Longitude">
                    <ChipField source="longitude" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Created At">
                    <ChipField source="createdAt" size="medium" />
                </Labeled>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Labeled title="Updated At">
                    <ChipField source="updatedAt" size="medium" />
                </Labeled>
            </Grid>
        </Grid>
    )
};

export default UserAddressView;
