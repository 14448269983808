import { Edit, SimpleForm, TabbedShowLayout } from "react-admin";
import useChoices from "../../hooks/useChoices";
import { CommonEdit } from "./CommonEdit";

export const SubcategoryEdit = () => {
  const categoryChoices = useChoices("category");

  return (
    <Edit>
      <SimpleForm>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="General">
            <CommonEdit
              fields={["categoryId", "name", "nameAr", "isActive", "image"]}
              choices={{ category: categoryChoices }}
            />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  );
};
