import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const TagView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={["image", "name", "nameAr", "createdAt", "updatedAt"]}
          isView={true}
          labels={["Image", "Name", "Arabic Name", "Created At", "Updated At"]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
