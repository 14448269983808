import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const CategoryView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={[
            "name",
            "nameAr",
            "createdAt",
            "updatedAt",
            "subcategories",
            "priority",
            "isActive",
            "image",
            "bannerImages"
          ]}
          isView={true}
          labels={[
            "Name",
            "Arabic Name",
            "Created Date",
            "Updated Date",
            "Subcategories",
            "Priority",
            "Is Active",
            "Image",
            "Banner Images"
          ]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
