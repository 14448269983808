import { ArrayField, Button, Datagrid, ImageField, TextField, useRecordContext } from "react-admin";
import { useQuantityPopup } from "../utils/editQuantity";
import { Modal } from "@mui/material";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


export const OrderProductList = ({ source }) => {
    const { open, productId, handleOpenEditQuantity, handleSaveQuantity, handleClosePopup } = useQuantityPopup();
    var fieldRef = useRef();
    const { id } = useParams();

    const OpenPopupButton = () => {
        const record = useRecordContext();
        const handleClick = () => {
            handleOpenEditQuantity(record.id)
        };
        return <Button onClick={handleClick}>Edit Quantity</Button>;
    }

    const Popup = ({ title }) => {
        return (
            <div className="popup-box">
                <div className="box">
                    <h1>{title}</h1>
                    <input ref={fieldRef} type="number" id="quantity" /><br /><br />                    
                    <Button onClick={() => {handleSaveQuantity(id, productId, fieldRef.current.value)}}>Save</Button>
                    <Button onClick={handleClosePopup}>Close</Button>
                </div>
            </div>
        );
    };

    return (
        <>
            <ArrayField source={source}>
                <Datagrid className="address-grid-field" bulkActionButtons={false}>
                    <ImageField key="image" source="image" />;
                    <TextField source="barcode" />
                    <TextField source="name" />
                    <TextField source="description" />
                    <TextField source="country" />
                    <TextField source="price" />
                    <TextField source="unit" />
                    <TextField source="quantity" />
                    <OpenPopupButton />
                </Datagrid>
                <Modal
                    open={open}
                    onAfterOpen={() => { }}
                    onRequestClose={handleClosePopup}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <Popup
                        title={"Enter Quantity"}
                    />

                </Modal>
            </ArrayField>
        </>
    )
};
