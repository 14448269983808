import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
    RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";
import { bannerTypes } from "./bannerTypesDataProvider";

const bannerDataProvider = {
    deleteMany: async (resource, params) => {
        let body = {
            bannerIds: params.ids,
        }

        try {
            await mainDataProviderClient(
                `${resource}`,
                RequestTypes.update,
                {
                    method: "PATCH",
                    body: JSON.stringify(body),
                }
            );

            return { data: [] };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Banner");
        }
    },
    create: async (resource, params) => {
        let formData = new FormData();
        formData.append("actionUrl", params.data["actionUrl"] ?? "");
        formData.append("priority", params.data["priority"]);
        formData.append("location", params.data["bannerTypes"]);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}`,
                RequestTypes.create,
                {
                    method: "POST",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? `Failed to create ${resource}`);
        }
    },
    getList: async (resource, params) => {
        const url = createUrl(resource, params);

        const response = await mainDataProviderClient(
            url,
            RequestTypes.getAll
        );

        return {
            data: response.data.map((item) => ({
                id: item.id,
                actionUrl: item.actionUrl,
                image: item.imageUrl,
                location: bannerTypes.find((bannerType) => { return bannerType.id == item.location }).name,
                createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
                updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
            })),
            total: response.total,
        };
    },

    getOne: async (resource, params) => {
        const response = await mainDataProviderClient(
            `${resource}/${params.id}`,
            RequestTypes.getById
        );

        return {
            data: {
                ...response.data,
                id: params.id,
                createdAt: DateFormatter.instance.formatDateUTC(response.data.createdAt),
                updatedAt: DateFormatter.instance.formatDateUTC(response.data.updatedAt),
                actionUrl: response.data.actionUrl,
                priority: response.data.priority,
                bannerTypes: bannerTypes.find((item) => { return item.id == response.data.location }),
                image: response.data.imageUrl
            },
        };
    },

    update: async (resource, params) => {
        let formData = new FormData();
        formData.append("actionUrl", params.data["actionUrl"] ?? "");
        formData.append("priority", params.data["priority"]);
        formData.append("location", params.data["bannerTypes"].id);
        formData.append("file", params.data["image"].rawFile);

        try {
            const response = await mainDataProviderClient(
                `${resource}/update-banner/${params.id}`,
                RequestTypes.update,
                {
                    method: "PATCH",
                    body: formData,
                }
            );

            return { data: response.data };
        } catch (error) {
            throw new Error(error.message ?? "Failed to update Banner");
        }
    },
};
export default bannerDataProvider;
