import React from "react";
import {
  Datagrid,
  TextField,
  ShowButton,
  ImageField,
  BooleanField,
  Button,
  DeleteButton,
} from "react-admin";

export const CommonList = ({ fields, includeBulkActions, handleOpenStatusPopup, includeDelete }) => (
  <Datagrid rowClick="edit" bulkActionButtons={includeBulkActions}>
    {fields.map((field) => {
      if (field === "image") {
        return <ImageField key={field} source={field} />;
      }
      if (field === "active" || field === "isActive" || field === "accountVerification") {
        return <BooleanField key={field} source={field} />;
      }
      if (field === "editStatusButton") {
        return <Button key="editStatusButton" onClick={handleOpenStatusPopup}>Edit Status</Button>
      }
      else {
        return <TextField source={field} key={field} />;
      }
    })}
    <ShowButton />
    {
      includeDelete &&
      <DeleteButton />
    }
  </Datagrid>
);
