import {
  SelectInput,
  TextInput,
  ImageInput,
  NumberInput,
  ImageField,
  ArrayInput,
  SimpleFormIterator,
  BooleanField,
  BooleanInput,
  DateInput,
  ChipField,
  ArrayField,
  SingleFieldList,
  SelectArrayInput,
  EmailField,
  TextField,
  Datagrid,
  UrlField,
} from "react-admin";
import { ColorInput } from "react-admin-color-picker";
import NutritionGrid from "../component/layout/NutritionGrid";
import UserAddressView from "../component/view/UserAddressView";
import UserOrdersView from "../component/view/UserOrdersView";
import { OrderProductList } from "../component/orderProductList";

export const getFieldType = (field, choices, isView) => {

  const commonProps = {
    key: field,
    source: field,
  };

  if (isView) {
    switch (field) {
      case "email":
        return <EmailField {...commonProps} />;
      case "active":
      case "isActive":
      case "isFeatured":
      case "accountVerification":
      case "userInfo.active":
      case "userInfo.isActive":
      case "userInfo.accountVerification":
        return <BooleanField {...commonProps} />;
      case "productName":
      case "productCountry":
      case "nameAr":
      case "name":
      case "ingredient":
      case "ingredientAr":
      case "storage":
      case "storageAr":
      case "priority":
        return <ChipField {...commonProps} />;
      case "subcategoryName":
        return <ChipField {...commonProps} />;
      case "productImageUrl":
        return <ImageField {...commonProps} />;
      case "productPrice":
        return <ChipField {...commonProps} />;
      case "productDescription":
      case "productGeneralDescription":
      case "description":
      case "generalDescription":
      case "descriptionAr":
      case "generalDescriptionAr":
        return <TextField {...commonProps} multiline={true} />
      // return <ChipField {...commonProps} className="chip-style" />;
      case "discountValue":
        return <ChipField {...commonProps} />;
      case "productIsDiscounted":
        return <BooleanField {...commonProps} />;
      case "createdAt":
      case "updatedAt":
        return <ChipField {...commonProps} />;
      case "images":
      case "image":
        return <ImageField {...commonProps} />;
      case "category":
        return <ChipField {...commonProps} />;
      case "nutrition":
        return <NutritionGrid {...commonProps} isView={isView} />;
      case "tags":
        return (
          <ArrayField source="tags">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        );
      case "addresses":
        return (
          <ArrayField source="addresses">
            <Datagrid expand={<UserAddressView {...commonProps} />} className="address-grid-field" bulkActionButtons={false}>
              <TextField source="address" />
              <TextField source="addressTitle" />
              <BooleanField source="isDefaultAddress" />
            </Datagrid>
          </ArrayField>
        );
      case "orders":
        return (
          <ArrayField source="orders">
            {/* <Datagrid className="address-grid-field" bulkActionButtons={false}> */}
            <Datagrid expand={<UserOrdersView {...commonProps} />} className="address-grid-field" bulkActionButtons={false} >
              <TextField source="invoiceNumber" label="Invoice Number" />
              <TextField source="scheduledDate" />
              <TextField source="scheduledTime" />
              <TextField source="totalAmount" />
            </Datagrid>
          </ArrayField>
        );
      case "products":
        return (
          <OrderProductList source={"products"} />
        );
      case "subcategories":
        return (
          <ArrayField source="subcategories">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        );
        case "bannerImages":
        return (
          <ArrayField {...commonProps}>
            {/* <SingleFieldList linkType={false}> */}
            <Datagrid bulkActionButtons={false}>
              <ImageField source="src" label="Image" />
              <UrlField source="link" label="Link" />
            {/* </SingleFieldList> */}
            </Datagrid>
          </ArrayField>
        );
      default:
        return <ChipField {...commonProps} />;
    }
  } else {
    switch (field) {
      case "bannerImages":
        return (
          <ArrayInput {...commonProps}>
            <SimpleFormIterator label={false}>
              <ImageInput {...commonProps} multiple={false}>
                <ImageField source="src" title="image" />
              </ImageInput>
              <TextInput source="link" label="Link" />
            </SimpleFormIterator>
          </ArrayInput>
        );
      case "updatedAt":
      case "createdAt":
        return <DateInput {...commonProps} className="text-input" />;
      case "ingredient":
      case "ingredientAr":
      case "storage":
      case "storageAr":
      case "generalDescription":
      case "generalDescriptionAr":
      case "description":
      case "descriptionAr":
        return (
          <TextInput {...commonProps} multiline={true} className="text-input" />
        );
      case "name":
        return <TextInput {...commonProps} className="text-field" />;
      case "active":
      case "isActive":
      case "isFeatured":
      case "accountVerification":
      case "userInfo.active":
        return <BooleanInput {...commonProps} />;
      case "countryId":
      case "subcategoryId":
      case "category":
      case "categoryId":
      case "brandId":
      case "unitId":
        return (
          <SelectInput
            {...commonProps}
            choices={getChoicesForField(field, choices)}
            className="select-input"
          />
        );
      case "bannerTypes":
      case "bannerTypes.id":
        return (
          <SelectInput
            {...commonProps}
            choices={getChoicesForField(field, choices)}
            className="select-input"
          />
        );
      case "Quantity":
      case "quantity":
      case "priority":
        return <NumberInput {...commonProps} className="number-input" />;
      case "price":
      case "discountValue":
        return <NumberInput {...commonProps} className="number-input" />;
      case "images":
        return (
          <ImageInput {...commonProps} multiple={true}>
            <ImageField source="src" title="title" />
          </ImageInput>
        );
      case "image":
        return (
          <ImageInput {...commonProps}>
            <ImageField source="src" title="title" />
          </ImageInput>
        );
      case "productIsDiscounted":
        return <BooleanInput {...commonProps} />;
      case "nutrition":
        return <NutritionGrid {...commonProps} />;
      case "color":
        return <ColorInput {...commonProps} className="color-input" />;
      case "tags":
      case "tagIds":
        return (
          <SelectArrayInput
            {...commonProps}
            label="tags"
            choices={getChoicesForField(field, choices)}
            className="text-input"
          />
        );
      case "subcategoryIds":
        return (
          <SelectArrayInput
            {...commonProps}
            label="Subcategories"
            choices={getChoicesForField(field, choices)}
            className="text-input"
          />
        );
      case "userName":
      case "userEmail":
      case "userRole":
      case "userPhoneNumber":
        return <TextInput disabled {...commonProps} className="text-field" />;
      default:
        return <TextInput {...commonProps} className="text-input" />;
    }
  }
};

export const getChoicesForField = (field, choices) => {
  switch (field) {
    case "subcategoryIds":
      return choices.subcategories;
    case "subcategory":
    case "subcategoryId":
      return choices.subcategory;
    case "brand":
    case "brandId":
      return choices.brand;
    case "unit":
    case "unitId":
      return choices.unit;
    case "country":
    case "countryId":
      return choices.country;
    case "category":
    case "categoryId":
      return choices.category;
    case "tags":
    case "tagIds":
      return choices.tags;
    case "bannerTypes":
    case "bannerTypes.id":
      return choices.bannerTypes;
    default:
      return [];
  }
};
