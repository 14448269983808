import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const SubcategoryView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={["category.name", "name", "nameAr", "isActive", "image"]}
          isView={true}
          labels={["Category", "Name", "Arabic Name", "Is Active", "Image"]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
