import mainDataProviderClient, {
  RequestTypes,
} from "../common/mainDataProviderClient";

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const response = await mainDataProviderClient(
        `auth/login-admin`,
        RequestTypes.general,
        {
          method: "POST",
          body: JSON.stringify({
            email: username,
            password: password,
          }),
        }
      );
      const accessToken = response.data.token;
      localStorage.setItem("accessToken", accessToken);
      return { data: response.data };
    } catch (error) {
      throw new Error(error?.message);
    }
  },
  logout: async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      try {
        await mainDataProviderClient(`auth/logout`, RequestTypes.general, {
          method: "POST",
        });
        localStorage.removeItem("accessToken");
        return Promise.resolve();
      } catch (error) {
        throw new Error(error?.message);
      }
    } else {
      localStorage.removeItem("accessToken");
      return Promise.resolve();
    }
  },
  checkAuth: async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      return Promise.reject(new Error("User is not authenticated"));
    }
  },
  checkError: async (error) => {
    const { status } = error;
    if (status === 401 || status === 403) {
      return Promise.reject(error);
    }
    return Promise.resolve();
  },
  getIdentity: () => Promise.resolve(),
  getPermissions: () => Promise.resolve(),
};

export default authProvider;
