import { CreateView } from "../createView";
import { CommonForm } from "./CommonForm";

export const PriceUnitForm = (props) => (
  <CreateView resource="price">
    <CommonForm
      fields={["name", "nameAr"]}
      includeSimpleForm={true}
      {...props}
    />
  </CreateView>
);
