import { CreateView } from "../createView";
import { CommonForm } from "./CommonForm";

export const BrandForm = (props) => {
  return (
    <CreateView resource="brand">
      <CommonForm
        fields={["name", "nameAr", "image"]}
        includeSimpleForm={true}
        {...props}
      />
    </CreateView>
  );
};
