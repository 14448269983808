import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const priceUnitDataProvider = {
  deleteMany: async (resource, params) => {
    let body = {
      priceIds: params.ids,
    }

    try {
      await mainDataProviderClient(
        `${resource}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      return { data: [] };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update brand");
    }
  },
  create: async (resource, params) => {
    let body = {
      unit: params.data["name"],
      unitAr: params.data["nameAr"],
    };

    try {
      const response = await mainDataProviderClient(
        `${resource}`,
        RequestTypes.create,
        {
          method: "POST",
          body: JSON.stringify(body),
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to create unit");
    }
  },
  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(
      url,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((priceUnit) => ({
        id: priceUnit.id,
        name: priceUnit.unit,
        nameAr: priceUnit.unitAr,
        createdAt: DateFormatter.instance.formatDateUTC(priceUnit.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(priceUnit.updatedAt),
      })),
      total: response.total,
    };
  },

  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );
    return {
      data: {
        ...response.data,
        id: params.id,
        name: response.data.unit,
        nameAr: response.data.unitAr,
        createdAt: DateFormatter.instance.formatDateUTC(
          response.data.createdAt
        ),
        updatedAt: DateFormatter.instance.formatDateUTC(
          response.data.updatedAt
        ),
      },
    };
  },

  update: async (resource, params) => {
    let body = {
      unit: params.data["name"],
      unitAr: params.data["nameAr"],
    };

    try {
      const path = `${resource}/update-price/${params.id}`;

      const response = await mainDataProviderClient(path, RequestTypes.update, {
        method: "PATCH",
        body: JSON.stringify(body),
      });

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update price unit");
    }
  },
};

export default priceUnitDataProvider;
