import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const categoryDataProvider = {
  create: async (resource, params) => {
    console.log(params);
    let formData = new FormData();
    formData.append("name", params.data["name"]);
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("color", params.data["color"] ?? "#ffffff");
    formData.append("file", params.data["image"].rawFile);
    formData.append("priority", params.data["priority"]);
    formData.append("isActive", params.data["isActive"]);
    for (let i = 0; i < params.data["subcategoryIds"]?.length; i++) {
      formData.append(`subCategories[${i}]`, params.data["subcategoryIds"][i]);
    }
    params.data["bannerImages"].forEach((bannerImage, index) => {
      formData.append(`bannerFiles`, bannerImage.bannerImages.rawFile);
      formData.append(`bannerFilesActionUrl[${index}][actionUrl]`, bannerImage.link);
    });
    try {
      const response = await mainDataProviderClient(
        `${resource}`,
        RequestTypes.create,
        {
          method: "POST",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? `Failed to create ${resource}`);
    }
  },
  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        name: response.data.name,
        nameAr: response.data.nameAr,
        color: response.data.color,
        image: response.data.imageUrl,
        bannerImages: response.data.banners?.map((item) => { return { src: item.imageUrl, link: item.actionUrl } }) ?? [],
        priority: response.data.priority,
        isActive: response.data.isActive ?? false,
        subcategories: response.data.subCategories,
        subcategoryIds: response.data.subCategories.map((item) => item.id),
        createdAt: DateFormatter.instance.formatDateUTC(
          response.data.createdAt
        ),
        updatedAt: DateFormatter.instance.formatDateUTC(
          response.data.updatedAt
        ),
      },
    };
  },
  update: async (resource, params) => {
    let formData = new FormData();
    formData.append("name", params.data["name"]);
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("color", params.data["color"]);
    formData.append("file", params.data["image"].rawFile);
    formData.append("priority", params.data["priority"]);
    formData.append("isActive", params.data["isActive"]);
    for (let i = 0; i < params.data["subcategoryIds"].length; i++) {
      formData.append(`subCategories[${i}]`, params.data["subcategoryIds"][i]);
    }
    params.data["bannerImages"].forEach((bannerImage, index) => {
      if (bannerImage.bannerImages) {

        formData.append(`bannerFiles`, bannerImage.bannerImages.rawFile);
      }
      formData.append(`bannerFilesActionUrl[${index}][actionUrl]`, bannerImage.link);
    });
    try {
      const response = await mainDataProviderClient(
        `${resource}/${params.id}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update category");
    }
  },
  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(
      url,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((item) => ({
        id: item.id,
        createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
        priority: item.priority,
        isActive: item.isActive,
        name: item.name,
        nameAr: item.nameAr,
        color: item.color,
        image: item.imageUrl,
      })),
      total: response.total,
    };
  },
  deleteMany: async (resource, params) => {
    let body = {
      categoryIds: params.ids,
    }

    try {
      await mainDataProviderClient(
        `${resource}/delete-category`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      return { data: [] };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update category");
    }
  }
};

export default categoryDataProvider;
