import useChoices from "../../hooks/useChoices";
import { CreateView } from "../createView";
import { CommonForm } from "./CommonForm";
import { TabbedForm, FormTab } from 'react-admin';

export const CategoryForm = (props) => {
  const subcategories = useChoices("subcategory");

  return (
    <CreateView resource={"Category"}>
      <TabbedForm {...props}>
        <FormTab label="General">
          <CommonForm
            fields={["name", "nameAr", "subcategoryIds", "priority", "isActive", "image"]}
            choices={{
              subcategories: subcategories,
            }}
            {...props}
          />
        </FormTab>
        <FormTab label="Banners">
          <CommonForm
            fields={["bannerImages"]}
            choices={{
              subcategories: subcategories,
            }}
            {...props}
          />
        </FormTab>
      </TabbedForm>
    </CreateView>
  );
};
