import moment from 'moment';

export default class DateFormatter {
    static instance = new DateFormatter();

    formatDate(date, format) {
        return moment(date).format(format ?? 'DD/MM/yyyy')
    }
    formatDateUTC(date, format) {
        return moment.utc(date).format(format ?? 'DD/MM/yyyy')
    }
}