import { BrowserRouter as Router } from "react-router-dom";
import mainDataProvider from "./dataProviders/common/mainDataProvider";
import authProvider from "./dataProviders/auth/authProvider";
import { Admin, Resource } from "react-admin";

import { Layout } from "react-admin";
import Dashboard from "./component/Dashboard";

import { ProductForm } from "./component/form/ProductForm";
import { BrandForm } from "./component/form/BrandForm";
import { PriceUnitForm } from "./component/form/PriceUnitForm";

import { BrandList } from "./component/list/BrandList";
import { PriceUnitList } from "./component/list/PriceUnitList";

import { ProductView } from "./component/view/ProductView";
import { CategoryView } from "./component/view/CategoryView";
import { SubcategoryView } from "./component/view/SubcategoryView";
import { BrandView } from "./component/view/BrandView";
import { TagView } from "./component/view/TagView";
import { UserView } from "./component/view/UserView";
import { PriceUnitView } from "./component/view/PriceUnitView";

import { BrandEdit } from "./component/edit/BrandEdit";
import { TagEdit } from "./component/edit/TagEdit";
import { PriceUnitEdit } from "./component/edit/PriceUnitEdit";
import { ProductEdit } from "./component/edit/ProductEdit";
import { CategoryEdit } from "./component/edit/CategoryEdit";
import { SubcategoryEdit } from "./component/edit/SubcategoryEdit";
import { UserEdit } from "./component/edit/UserEdit";

import { SubcategoryForm } from "./component/form/SubcategoryForm";
import { CategoryForm } from "./component/form/CategoryForm";
import { TagForm } from "./component/form/TagForm";

import { TagList } from "./component/list/TagList";
import UserList from "./component/list/UserList";
import { ProductList } from "./component/list/ProductList";
import { CategoryList } from "./component/list/CategoryList";
import { SubcategoryList } from "./component/list/SubcategoryList";

import { InventoryReportList } from "./component/list/InventoryReportList";
import { SaleReportList } from "./component/list/SaleReportList";
import { InvoiceReportList } from "./component/list/InvoiceReportList";

import CategoryIcon from "@mui/icons-material/Category";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import QueueIcon from "@mui/icons-material/Queue";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import StoreIcon from "@mui/icons-material/Store";
import DescriptionIcon from "@mui/icons-material/Description";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import SmsIcon from "@mui/icons-material/Sms";

import { BannerView } from "./component/view/BannerView";
import { BannerEdit } from "./component/edit/BannerEdit";
import { BannerForm } from "./component/form/BannerForm";
import { BannerList } from "./component/list/BannerList";
import { OrderList } from "./component/list/OrderList";
import { CartList } from "./component/list/CartList";
import { InvoiceReportView } from "./component/view/InvoiceReportView";
import OrderView from "./component/view/OrderView";
import { HeartBroken } from "@mui/icons-material";

const App = () => (
  <Router>
    <Admin
      dataProvider={mainDataProvider}
      authProvider={authProvider}
      layout={Layout}
      dashboard={Dashboard}
    >
      <Resource
        name="brand"
        list={BrandList}
        create={BrandForm}
        show={BrandView}
        edit={BrandEdit}
        delete={BrandList}
        icon={StoreIcon}
      />
      <Resource
        name="price"
        options={{ label: "Units" }}
        list={PriceUnitList}
        create={PriceUnitForm}
        show={PriceUnitView}
        edit={PriceUnitEdit}
        delete={PriceUnitList}
        icon={AttachMoneyIcon}
      />
      <Resource
        name="category"
        list={CategoryList}
        create={CategoryForm}
        show={CategoryView}
        edit={CategoryEdit}
        delete={CategoryList}
        icon={CategoryIcon}
      />
      <Resource
        name="subcategory"
        list={SubcategoryList}
        create={SubcategoryForm}
        show={SubcategoryView}
        edit={SubcategoryEdit}
        delete={SubcategoryList}
        icon={CategoryIcon}
      />
      <Resource
        name="products"
        list={ProductList}
        create={ProductForm}
        show={ProductView}
        edit={ProductEdit}
        delete={ProductList}
        icon={QueueIcon}
      />
      <Resource
        name="tags"
        list={TagList}
        create={TagForm}
        show={TagView}
        edit={TagEdit}
        delete={TagList}
        icon={LocalOfferIcon}
      />
      <Resource
        name="banner"
        list={BannerList}
        show={BannerView}
        edit={BannerEdit}
        icon={SmsIcon}
        create={BannerForm}
      />
      <Resource
        name="users"
        list={UserList}
        show={UserView}
        edit={UserEdit}
        icon={PeopleIcon}
      />
      <Resource
        name="cart"
        list={CartList}
        // show={OrderView}
        options={{ label: "Abandoned Carts" }}
        icon={HeartBroken}
      />

      <Resource
        name="orders"
        list={OrderList}
        show={OrderView}
        icon={ShoppingBasketIcon}
      />
      {/* <Resource
        name="inventory-report"
        list={InventoryReportList}
        icon={InventoryIcon}
      />
      <Resource
        name="sales-report"
        list={SaleReportList}
        icon={RequestQuoteIcon}
      /> */}
    </Admin>
  </Router>
);

export default App;
