import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const subcategoryDataProvider = {
  deleteMany: async (resource, params) => {
    let body = {
      subCategoryIds: params.ids,
    }

    try {
      await mainDataProviderClient(
        `${resource}/delete-sub-category`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      return { data: [] };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update brand");
    }
  },
  create: async (resource, params) => {
    let formData = new FormData();
    formData.append("categoryId", params.data["category"]);
    formData.append("name", params.data["name"]);
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("color", "#ffffff");
    formData.append("file", params.data["image"].rawFile);
    formData.append("isActive", params.data["isActive"]);    

    try {
      const response = await mainDataProviderClient(
        `${resource}`,
        RequestTypes.create,
        {
          method: "POST",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? `Failed to create ${resource}`);
    }
  },

  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(
      url,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((item) => ({
        id: item.id,
        createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
        category: item.category?.name ?? "",
        name: item.name,
        nameAr: item.nameAr,
        isActive: item.isActive,
        image: item.imageUrl,
      })),
      total: response.total,
    };
  },

  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        category: response.data?.category ?? "",
        categoryId: response.data?.category?._id ?? "",
        name: response.data.name,
        nameAr: response.data.nameAr,
        isActive: response.data.isActive,
        image: response.data.imageUrl,
      },
    };
  },
  update: async (resource, params) => {
    let formData = new FormData();
    if(params.data["categoryId"]) {
      formData.append("categoryId", params.data["categoryId"]);
    }
    formData.append("name", params.data["name"]);
    formData.append("nameAr", params.data["nameAr"]);
    formData.append("file", params.data["image"].rawFile);
    formData.append("isActive", params.data["isActive"]);
    
    try {
      const response = await mainDataProviderClient(
        `${resource}/${params.id}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update subcategory");
    }
  },
};

export default subcategoryDataProvider;
