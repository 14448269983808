import React from "react";
import { FormTab, TabbedForm } from "react-admin";
import { CreateView } from "../createView";
import { CommonForm } from "./CommonForm";
import useChoices from "../../hooks/useChoices";

export const ProductForm = (props) => {
  const subCategory = useChoices("subcategory");
  const brand = useChoices("brand");
  const unit = useChoices("price");
  const country = useChoices("country");
  const tags = useChoices("tags");

  return (
    <CreateView resource={"Product"}>
      <TabbedForm {...props}>
        <FormTab label="General">
          <CommonForm
            isGrid={true}
            resource="Product"
            fields={[
              "name",
              "sku",
              "sort_order",
              "nameAr",
              "barcode",
              "brandId",
              "subcategoryId",
              "unitId",
              "price",
              "discountValue",
              "countryId",
              "quantity",
              "description",
              "descriptionAr",
              "generalDescription",
              "generalDescriptionAr",
            ]}
            choices={{
              subcategory: subCategory,
              brand: brand,
              unit: unit,
              country: country,
            }}
          />
        </FormTab>
        <FormTab label="Images">
          <CommonForm isGrid={false} fields={["images"]} />
        </FormTab>

        <FormTab label="nutrition">
          <CommonForm isGrid={false} fields={["nutrition"]} />
        </FormTab>
        <FormTab label="Extra">
          <CommonForm
            isGrid={false}
            fields={[
              "isActive",
              "isFeatured",
              "tags",
              "ingredient",
              "ingredientAr",
              "storage",
              "storageAr",
            ]}
            choices={{
              tags: tags,
            }}
          />
        </FormTab>
      </TabbedForm>
    </CreateView>
  );
};
