// import { Edit, SimpleForm, TabbedShowLayout } from "react-admin";
import useChoices from "../../hooks/useChoices";
import { CommonEdit } from "./CommonEdit";
import { Edit, FormTab, SaveButton, TabbedForm, Toolbar } from "react-admin";

const EditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
  </Toolbar>
);

export const ProductEdit = (props) => {
  const subCategory = useChoices("subcategory");
  const brand = useChoices("brand");
  const unit = useChoices("price");
  const country = useChoices("country");
  const tags = useChoices("tags");
  return (
    <Edit>
      <TabbedForm {...props} toolbar={<EditToolbar />}>
        <FormTab label="General">
          <CommonEdit
            isGrid={true}
            resource="Product"
            fields={[
              "name",
              "nameAr",
              "barcode",
              "Sku",
              "SortOrder",
              "brandId",
              "subcategoryId",
              "unitId",
              "price",
              "discountValue",
              "countryId",
              "quantity",
              "description",
              "descriptionAr",
              "generalDescription",
              "generalDescriptionAr",
            ]}
            choices={{
              subcategory: subCategory,
              brand: brand,
              unit: unit,
              country: country,
            }}
          />
        </FormTab>
        <FormTab label="Images">
          <CommonEdit isGrid={false} fields={["images"]} />
        </FormTab>

        <FormTab label="nutrition">
          <CommonEdit isGrid={false} fields={["nutrition"]} />
        </FormTab>
        <FormTab label="Extra">
          <CommonEdit
            isGrid={false}
            fields={["isActive","isFeatured", "tagIds", "ingredient", "ingredientAr", "storage", "storageAr"]}
            choices={{
              tags: tags,
            }}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
