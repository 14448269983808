import { Grid } from "@mui/material";
import React from "react";
import { TextInput, NumberInput, ChipField } from "react-admin";

const NutritionGrid = ({ isView }) => {

  if (isView) {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ChipField source="nutrition.description" label="Description" size="medium" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChipField source="nutrition.per_100g_ml" label="Per 100g/ml" size="medium" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChipField source="nutrition.per_serving" label="Per serving" size="medium" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChipField source="nutrition.measure" label="Measure" size="medium" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ChipField source="nutrition.nrv" label="NRV" size="medium" />
        </Grid>
      </Grid>
    )
  } else {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInput source="nutrition.description" label="Description" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput source="nutrition.per_100g_ml" label="Per 100g/ml" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput source="nutrition.per_serving" label="Per serving" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput source="nutrition.measure" label="Measure" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberInput source="nutrition.nrv" label="NRV" />
        </Grid>
      </Grid>
    )
  }
};

export default NutritionGrid;
