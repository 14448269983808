import React, { useState } from 'react';
import { Button } from 'react-admin';
import '../index.css';
const EditStatusPopup = ({ title, statuses, onSave, onClose }) => {
    const [selectedStatus, setSelectedStatus] = useState(statuses[0]);
    const handleSave = () => {
        onSave(selectedStatus);
    };
    return (
        <div className="popup-overlay">

            <div className="popup-box">
                <div className="box">
                    <h1>{title}</h1>
                    <select value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
                        {statuses.map((status) => (
                            <option key={status} value={status}>{status}</option>
                        ))}
                    </select>
                    <br /><br />
                    <Button onClick={handleSave}>Save</Button>
                    <Button onClick={onClose}>Close</Button>
                </div>
            </div>
        </div>
    );
};

export default EditStatusPopup;
