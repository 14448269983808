import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const ProductView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={[
            "name",
            "nameAr",
            "barcode",
            "Sku",
            "SortOrder",
            "brand",
            "subcategory",
            "unit",
            "price",
            "discountValue",
            "country",
            "quantity",
            "description",
            "descriptionAr",
            "generalDescription",
            "generalDescriptionAr",
            "createdAt",
            "updatedAt",
          ]}
          isView={true}
          labels={[
            "Name",
            "Name Ar",
            "Barcode",
            "Sku",
            "SortOrder",
            "Brand",
            "Sub Category",
            "Unit",
            "Price",
            "Product Discount Value",
            "Country",
            "Quantity",
            "Description",
            "Description Ar",
            "General Description",
            "General Description Ar",
            "Created Date",
            "Updated Date",
          ]}
        />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Images">
        <CommonView
          fields={["images"]}
          isView={true}
          labels={["Images"]}
        />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="nutrition">
        <CommonView
          fields={["nutrition"]}
          isView={true}
          labels={["Nutrition"]}
        />
      </TabbedShowLayout.Tab>

      <TabbedShowLayout.Tab label="Extra">
        <CommonView
          fields={["isActive","isFeatured", "tags", "ingredient", "ingredientAr", "storage", "storageAr"]}
          isView={true}
          labels={["Is Active","Is Featured", "Tags", "Ingredient", "Ingredient Ar", "Storage", "Storage Ar"]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
