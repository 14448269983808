import { Grid, Typography } from "@mui/material";
import { getFieldType } from "../../utils/fieldUtils";
import "./commonView.css";

export const CommonView = ({ fields, isView, labels }) => (
  <Grid container spacing={2}>
    {fields.map((field, index) => (
      <Grid item xs={12} sm={6} key={field}>
        <Typography variant="subtitle1">{labels[index]}</Typography>
        {getFieldType(field, [], isView)}
      </Grid>
    ))}
  </Grid>
);
