import "./commonEdit.css";
import { getFieldType } from "../../utils/fieldUtils";

export const CommonEdit = ({ fields, choices }) =>{
 return( <>
    {fields.map((field) => (
      <>{getFieldType(field, choices)}</>
    ))}
  </>
)};
