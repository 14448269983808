import {
  CreateButton,
  downloadCSV,
  ExportButton,
  FilterButton,
  List,
  Pagination,
  TextInput,
  TopToolbar,
  useListContext,
  useNotify,
} from "react-admin";
import { trim, replace } from "lodash";
import { CommonList } from "./CommonList";
import CustomImportButton from "../customImportButton";
import ProductDataProvider from "../../dataProviders/productDataProvider";
import jsonExport from "jsonexport/dist";

const filters = [<TextInput label="Title" source="name" defaultValue="" />];

const exporter = (posts) => {
  const postsForExport = posts.map((post) => {
    return {
      id: trim(post.id),
      name: trim(post.name),
      nameAr: trim(post.nameAr),
      price: post.price,
      Sku: trim(post.Sku),
      barcode: trim(post.barcode),
      description: trim(post.description),
      descriptionAr: trim(post.descriptionAr),
      discountValue: trim(post.discountValue),
      generalDescription: trim(post.generalDescription),
      generalDescriptionAr: trim(post.generalDescriptionAr),
      ingredient: trim(post.ingredient),
      ingredientAr: trim(post.ingredientAr),
      isActive: trim(post.isActive),
      subCategories: trim(post.subCategories),
      stockQuantity: trim(post.stockQuantity),
      sortOrder: trim(post.SortOrder),
      storage: trim(post.storage),
      storageAr: trim(post.storageAr),
    };
  });

  jsonExport(
    postsForExport,
    {
      headers: [
        "id",
        "name",
        "nameAr",
        "price",
        "Sku",
        "barcode",
        "description",
        "descriptionAr",
        "discountValue",
        "generalDescription",
        "generalDescriptionAr",
        "ingredient",
        "ingredientAr",
        "isActive",
        "stockQuantity",
        "storage",
        "storageAr",
      ], // order fields in the export
    },
    (err, csv) => {
      downloadCSV(csv, "products"); // download as 'products.csv` file
    }
  );
};

const PostPagination = ({ total }) => (
  <Pagination total={total} rowsPerPageOptions={[10, 25, 50, 100]} />
);

const ListActions = ({ handleImport, handleUpdateBulkImport }) => (
  <TopToolbar>
    <CustomImportButton label="Create Products" onImport={handleImport} />
    <CustomImportButton
      label="Update Prices"
      onImport={handleUpdateBulkImport}
    />
    <FilterButton />
    <CreateButton />
    <ExportButton maxResults={10000} exporter={exporter} />
  </TopToolbar>
);

export const ProductList = (props) => {
  const notify = useNotify();

  const handleImport = async (parsedData) => {
    try {
      await ProductDataProvider.createMany("products", parsedData);
      notify(`Products added Successfully.`, { type: "success" });
    } catch (error) {
      notify(`${error}`, { type: "error" });
    }
  };

  const handleUpdateBulkImport = async (parsedData) => {
    try {
      await ProductDataProvider.updateMany("products", parsedData);
      notify(`Prices updated successfully.`, { type: "success" });
    } catch (error) {
      notify(`${error.message}`, { type: "error" });
    }
  };

  const { total } = useListContext();
  return (
    <List
      actions={
        <ListActions
          handleImport={handleImport}
          handleUpdateBulkImport={handleUpdateBulkImport}
        />
      }
      filters={filters}
      {...props}
      pagination={<PostPagination total={total} />}
      title="Product List"
    >
      <CommonList
        fields={[
          "image",
          "name",
          "country",
          "formatted_price",
          "quantity",
          "isActive",
          "isFeatured",
          "createdAt",
        ]}
        includeBulkActions={false}
        includeDelete={true}
      />
    </List>
  );
};
