import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";

export const bannerTypes = [
  {
    id: "home_page_section_1",
    name: "Home Page Desktop 1",
  },
  {
    id: "home_page_section_2",
    name: "Home Page Desktop 2",
  },

  {
    id: "home_page_mobile_1",
    name: "Home Page Mobile 1",
  },
  {
    id: "home_page_mobile_2",
    name: "Home Page Mobile 2",
  },
  // {
  //     "id": "home_page_section_3",
  //     "name": "Home Page Section 3"
  // },
  // {
  //     "id": "product_details",
  //     "name": "Product Details Page"
  // },
  // {
  //     "id": "filters_page",
  //     "name": "Filters Page"
  // },
  // {
  //     "id": "checkout",
  //     "name": "Checkout Page"
  // }
];

const bannerTypesDataProvider = {
  getList: async (resource, params) => {
    return {
      data: bannerTypes,
      total: bannerTypes.length,
    };
  },
  getOne: async (resource, params) => {
    const getOneResult = bannerTypes.find((item) => {
      return item.id == params.id;
    });
    return {
      data: {
        id: getOneResult.id,
        name: getOneResult.name,
      },
    };
  },
};

export default bannerTypesDataProvider;
