import { useEffect, useState } from "react";
import brandDataProvider from "../dataProviders/brandDataProvider";
import countryDataProvider from "../dataProviders/countryDataProvider";
import categoryDataProvider from "../dataProviders/categoryDataProvider";
import subcategoryDataProvider from "../dataProviders/subcategoryDataProvider";
import priceUnitDataProvider from "../dataProviders/priceUnitDataProvider";
import tagsDataProvider from "../dataProviders/tagDataProvider";
import bannerTypesDataProvider from "../dataProviders/bannerTypesDataProvider";

const useChoices = (resource) => {
  const [choices, setChoices] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getData();
        setChoices(response.data);
      } catch (error) {
      }
    };
    fetchData();
  }, []);

  async function getData() {
    switch (resource) {
      case "brand":
        return await brandDataProvider.getList("brand");
      case "country":
        return await countryDataProvider.getList("country");
      case "category":
        return await categoryDataProvider.getList("category");
      case "subcategory":
        return await subcategoryDataProvider.getList("subcategory");
      case "price":
        return await priceUnitDataProvider.getList("price");
      case "tags":
        return await tagsDataProvider.getList("tags");
      case "bannerTypes":
        return await bannerTypesDataProvider.getList("bannerTypes");
      default:
        return [];
    }
  }

  return choices;
};

export default useChoices;
