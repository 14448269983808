import { Grid } from "@mui/material";
import React from "react";
import { SimpleForm } from "react-admin";

import "./commonForm.css";
import { getFieldType } from "../../utils/fieldUtils";

export const CommonForm = ({ fields, choices, isGrid, includeSimpleForm }) => {
  const renderInnerView = () => {
    return (
      <div>
        {isGrid ? (
          <Grid container spacing={2}>
            {fields.map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                {getFieldType(field, choices)}
              </Grid>
            ))}
          </Grid>
        ) : (
          <div>{fields.map((field) => getFieldType(field, choices))}</div>
        )}
      </div>
    );
  };

  return (
    <div>
      {includeSimpleForm ? (
        <SimpleForm>{renderInnerView()}</SimpleForm>
      ) : (
        <div>{renderInnerView()}</div>
      )}
    </div>
  );
};
