import DateFormatter from "../utils/dateFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const brandDataProvider = {
  deleteMany: async (resource, params) => {
    let body = {
      brandIds: params.ids,
    }

    try {
      await mainDataProviderClient(
        `${resource}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: JSON.stringify(body),
        }
      );

      return { data: [] };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update brand");
    }
  },
  create: async (resource, params) => {
    let formData = new FormData();
    formData.append("source", params.data["name"]);
    formData.append("sourceAr", params.data["nameAr"]);
    formData.append("file", params.data["image"].rawFile);

    try {
      const response = await mainDataProviderClient(
        `${resource}`,
        RequestTypes.create,
        {
          method: "POST",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? `Failed to create ${resource}`);
    }
  },
  getList: async (resource, params) => {
    const url = createUrl(resource, params);

    const response = await mainDataProviderClient(
      url,
      RequestTypes.getAll
    );

    return {
      data: response.data.map((brand) => ({
        id: brand.id,
        name: brand.source,
        nameAr: brand.sourceAr,
        image: brand.imageUrl,
        createdAt: DateFormatter.instance.formatDateUTC(brand.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(brand.updatedAt),
      })),
      total: response.total,
    };
  },

  getOne: async (resource, params) => {
    const response = await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );

    return {
      data: {
        ...response.data,
        id: params.id,
        createdDate: response.data.createdAt,
        name: response.data.source,
        nameAr: response.data.sourceAr,
        image: response.data.imageUrl,
        createdAt: DateFormatter.instance.formatDateUTC(
          response.data.createdAt
        ),
        updatedAt: DateFormatter.instance.formatDateUTC(
          response.data.updatedAt
        ),
      },
    };
  },

  update: async (resource, params) => {
    let formData = new FormData();
    formData.append("source", params.data["name"]);
    formData.append("sourceAr", params.data["nameAr"]);
    formData.append("file", params.data["image"].rawFile);

    try {
      const response = await mainDataProviderClient(
        `${resource}/update-${resource}/${params.id}`,
        RequestTypes.update,
        {
          method: "PATCH",
          body: formData,
        }
      );

      return { data: response.data };
    } catch (error) {
      throw new Error(error.message ?? "Failed to update brand");
    }
  },
};
export default brandDataProvider;
