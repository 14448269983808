import { useParams } from "react-router-dom";
import {
  Datagrid,
  List,
  Pagination,
  TextField,
  TextInput,
  useListContext,
} from "react-admin";

import { CommonList } from "./CommonList";
// import EditStatusPopup from "../EditStatusPopup";

const filters = [<TextInput label="Title" source="name" defaultValue="" />];
const PostPagination = ({ total }) => (
  <Pagination total={total} rowsPerPageOptions={[10, 25, 50, 100]} />
);
export const CartList = (props) => {
  const { total } = useListContext();
  console.log(total);
  // const { open, handleOpenEditStatus, handleSaveStatus, handleClosePopup } =
  // const orderId = useParams();

  const handleOpenEditStatus = () => {};

  return (
    <List
      {...props}
      filters={filters}
      title="Orders List"
      pagination={<PostPagination total={total} />}
    >
      <Datagrid>
        {[
          "name",
          "email",
          "phoneNumber",
          "abandoned",
          "createdAt",
          "updatedAt",
        ].map((field) => {
          return <TextField source={field} key={field} />;
        })}
      </Datagrid>
      {/* <CommonList
        fields={["userId"]}
        includeBulkActions={false}
        handleOpenStatusPopup={() => handleOpenEditStatus(orderId)}
      /> */}
      {/* {open && (
        <EditStatusPopup
          title={"Change Status"}
          statuses={[
            "Confirmed",
            "Processing",
            "Out for Delivery",
            "Delivered",
            "Closed",
            "Cancel",
          ]}
          onSave={(newStatus) => handleSaveStatus(orderId, newStatus)}
          onClose={handleClosePopup}
          open={open}
        />
      )} */}
    </List>
  );
};
