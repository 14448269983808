import moment from "moment";
import { createUrl } from "../utils/urlUtils";
import { fetchWrapper } from "./common/mainDataProviderClient";

const timeDifferenceFromNow = (date) => {
  const now = moment();
  let result = "";
  const providedDate = moment(date);

  const duration = moment.duration(now.diff(providedDate));

  const months = Math.floor(duration.asMonths());
  const days = Math.floor(duration.asDays()) % 30;
  const hours = Math.floor(duration.asHours()) % 24;
  const minutes = Math.floor(duration.asMinutes()) % 60;

  if (months > 0) result = result + `${months} months `;
  if (days > 0) result = result + `${days} days `;
  if (hours > 0) result = result + `${hours} hours `;
  if (minutes > 0) result = result + `${minutes} minutes `;
  result = result + `ago `;
  return result;
  //   return `${months} months, ${days} days, ${hours} hours, and ${minutes} minutes ago`;
};

const cartDataProvider = {
  create: async (resource, params) => {
    return await Promise.resolve({
      data: [{ userId: "9084029348029384" }],
      total: 0,
    });
  },
  getList: async (resource, params) => {
    const url = createUrl(`${resource}/list`, params);
    let response = await fetchWrapper(url);

    let result = response.data.map((item) => ({
      id: item?._id,
      name: item.userId.info.general.name,
      email: item.userId.info.general.email,
      phoneNumber: item.userId.info.general.phoneNumber,
      createdAt: moment(item?.createdAt).format("DD - MMM - YYYY"),
      updatedAt: moment(item?.updatedAt).format("DD - MMM - YYYY"),
      abandoned: timeDifferenceFromNow(item?.updatedAt),
    }));

    console.log("--------------------result:", result);

    return await Promise.resolve({
      data: result,
      total: response.total,
    });
  },
  getOne: async (resource, params) => {
    return await Promise.resolve({ data: [], total: 0 });
  },
  update: async (resource, params) => {
    return await Promise.resolve({ data: [], total: 0 });
  },
};
export default cartDataProvider;
