import { fetchUtils } from "react-admin";

const api_url = "https://backend.freshsandouk.com";
// const api_url = "http://localhost:8080";
export const RequestTypes = {
  getAll: 1,
  getById: 2,
  create: 3,
  update: 4,
  delete: 5,
  general: 6,
};

const mainDataProviderClient = async (path, requestType, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  } else {
    options.headers = new Headers({ ...options.headers });
  }

  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    options.headers.set("Authorization", `Bearer ${accessToken}`);
    options.headers.set("accept-language", `en`);
  }

  const result = await fetchUtils.fetchJson(`${api_url}/${path}`, options);

  if (result.status >= 200 || result.status <= 300) {
    let data = {};
    switch (requestType) {
      case RequestTypes.getAll:
        data = JSON.parse(result.body);
        return {
          data: data.data.docs ?? data.data,
          total:
            data.data.totalDocs ?? data.data?.docs?.length ?? data.data.length,
        };
      case RequestTypes.getById:
      case RequestTypes.create:
      case RequestTypes.delete:
      case RequestTypes.update:
      case RequestTypes.general:
        data = JSON.parse(result.body);
        return { data: data.data };
      default:
        throw new Error("Request Type Error");
    }
  } else {
    const errorMessage =
      JSON.parse(result.body).message ?? "Something went wrong";
    throw new Error(errorMessage);
  }
};

export const fetchWrapper = async (resource, options = {}) => {
  options.headers = new Headers({ Accept: "application/json" });
  const accessToken = localStorage.getItem("accessToken");
  if (accessToken) {
    options.headers.set("Authorization", `Bearer ${accessToken}`);
    options.headers.set("accept-language", `en`);
  }
  let response = await fetchUtils.fetchJson(`${api_url}/${resource}`, options);
  return JSON.parse(response?.body);
};

export default mainDataProviderClient;
