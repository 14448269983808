import { Edit, SimpleForm, TabbedShowLayout } from "react-admin";
import { CommonEdit } from "./CommonEdit";
import useChoices from "../../hooks/useChoices";

export const BannerEdit = () => {
  const bannerTypes = useChoices("bannerTypes");

  return (
    <Edit>
      <SimpleForm>
        <TabbedShowLayout>
          <TabbedShowLayout.Tab label="General">
            <CommonEdit
              fields={["bannerTypes.id", "priority", "actionUrl", "image"]}
              choices={{
                bannerTypes: bannerTypes
              }}
            />
          </TabbedShowLayout.Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  )
};
