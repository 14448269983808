import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";

export const BrandView = () => (
  <Show>
    <TabbedShowLayout>
      <TabbedShowLayout.Tab label="General">
        <CommonView
          fields={["name", "nameAr", "createdAt", "updatedAt", "image"]}
          isView={true}
          labels={["Name", "Arabic Name", "Created At", "Updated At", "Image"]}
        />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
);
