import { utils, read } from "xlsx";
import { Button } from "react-admin";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const CustomImportButton = ({ onImport, label }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const fileContent = event.target.result;

          // Parse the Excel file
          const workbook = read(fileContent, {
            type: "string",
            charset: "utf-8",
          });

          // Create an array to store sheet data
          const sheetsData = [];

          workbook.SheetNames.forEach((sheetName) => {
            const sheetData = utils.sheet_to_json(workbook.Sheets[sheetName]);
            sheetsData.push({ sheetName, data: sheetData });
          });

          // Pass the array of sheet data to the onImport callback
          onImport(sheetsData);
        };

        if (file?.type === "text/csv") reader.readAsText(file);
        else reader.readAsArrayBuffer(file);
      });
    },
    [onImport]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    // accept: {
    //   "text/csv": [".csv"],
    //   "application/vnd.ms-excel": [".xls"],
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
    //     ".xlsx",
    //   ],
    // },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Button
        style={{
          width: 200,
          color: "white",
          backgroundColor: "rgb(33,150,243)",
        }}
        label={label}
      />
    </div>
  );
};

export default CustomImportButton;
