import { Edit, useNotify, useRedirect } from "react-admin";
import useChoices from "../../hooks/useChoices";
import { CommonEdit } from "./CommonEdit";
import { TabbedForm, FormTab } from 'react-admin';

export const CategoryEdit = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const subcategories = useChoices("subcategory");

  const handleSuccess = () => {
    notify("Changes saved");
    redirect("/category");
  };

  return (
    <Edit {...props}>
      <TabbedForm onSuccess={handleSuccess}>
        <FormTab label="General">
          <CommonEdit
            fields={["name", "nameAr", "subcategoryIds", "priority", "isActive", "image"]}
            choices={{
              subcategories: subcategories,
            }}
          />
        </FormTab>
        <FormTab label="Banners">
          <CommonEdit
            fields={["bannerImages"]}
            choices={{
              subcategories: subcategories,
            }}
          />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
