import { useState } from 'react';
import orderDataProvider from '../dataProviders/orderDataProvider';
import { useNotify, useRefresh } from 'react-admin';

export const useStatusPopup = () => {
    const [open, setOpen] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();


    const handleSaveStatus = async (orderId, newStatus) => {

        await orderDataProvider.updateOrderStatus('order', { orderId, newStatus }).then(() => {
            refresh();
            notify("Successfully updated status.", { type: "success" });
            setOpen(false);
        }).catch((error) => {
            notify("Failed at updating status.", { type: "error" });
            setOpen(false);
        })
    };

    const handleClosePopup = () => {
        setOpen(false);
    };

    const handleOpenEditStatus = () => {
        setOpen(true);
    };

    return { open, handleOpenEditStatus, handleSaveStatus, handleClosePopup };
};
