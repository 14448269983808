import { useState } from 'react';
import orderDataProvider from '../dataProviders/orderDataProvider';
import { useNotify, useRefresh } from 'react-admin';

export const useQuantityPopup = () => {
    const [open, setOpen] = useState(false);
    const [productId, setProductId] = useState(false);
    const refresh = useRefresh();
    const notify = useNotify();

    const handleOpenEditQuantity = (productIdToBeEdited) => {
        setProductId(productIdToBeEdited);
        setOpen(true);
    };

    const handleSaveQuantity = async (orderId, productId, newQuantity) => {
        await orderDataProvider.updateOrderQuantity('order', { orderId, productId, newQuantity }).then(() => {
            refresh();
            notify("Successfully updated quantity", { type: "success" });
            setOpen(false);
        }).catch((error) => {
            notify("Failed at updating quantity", { type: "error" });
            setOpen(false);
        })
    };

    const handleClosePopup = () => {
        setOpen(false);
    };

    return { open, productId, handleOpenEditQuantity, handleSaveQuantity, handleClosePopup };
};
