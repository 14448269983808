import { List, Pagination, TextInput, useListContext } from "react-admin";
import { CommonList } from "./CommonList";

const filters = [
  <TextInput label="Title" source="name" defaultValue="" />,
];
const PostPagination = ({ total }) => <Pagination total={total} rowsPerPageOptions={[10, 25, 50, 100]} />;
export const PriceUnitList = (props) => {
  const { total } = useListContext();
  return (
    <List filters={filters} {...props} pagination={<PostPagination total={total} />} title="Unit List">
      <CommonList fields={["name", "nameAr", "createdAt", "updatedAt"]} includeBulkActions={true} />
    </List>
  )
};
