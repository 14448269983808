import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Title, useNotify } from "react-admin";
import statisticsDataProvider from "../dataProviders/statisticsDataProvider";
import { useEffect } from "react";
import { useState } from "react";

const Dashboard = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const notify = useNotify();

  useEffect(() => {
    // statisticsDataProvider.getStatistics('statistics').then((data) => {
    //   setTotalOrders(data?.data?.totalOrders ?? 0);
    //   setTotalSales(data?.data?.totalSales ?? 0);
    //   setTotalUsers(data?.data?.totalUsers ?? 0);
    // }).catch((error) => {
    //   notify(`${error}`, { type: "error" })
    // });
  }, [])

  const data = [
    { name: "Jan", sales: 4000, visits: 2400, orders: 2400 },
    { name: "Feb", sales: 3000, visits: 1398, orders: 2210 },
    { name: "Mar", sales: 2000, visits: 9800, orders: 2290 },
    { name: "Apr", sales: 2780, visits: 3908, orders: 2000 },
    { name: "May", sales: 1890, visits: 4800, orders: 2181 },
    { name: "Jun", sales: 2390, visits: 3800, orders: 2500 },
    { name: "Jul", sales: 3490, visits: 4300, orders: 2100 },
    { name: "Aug", sales: 4000, visits: 2400, orders: 2400 },
    { name: "Sep", sales: 3000, visits: 1398, orders: 2210 },
    { name: "Oct", sales: 2000, visits: 9800, orders: 2290 },
    { name: "Nov", sales: 2780, visits: 3908, orders: 2000 },
    { name: "Dec", sales: 1890, visits: 4800, orders: 2181 },
  ];

  return (
    <div>
      <Card style={{ marginBottom: "10px" }}>
        <Title title="Sandouk Administration" />
        <CardContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            {/* <div style={{ width: "33%" }}>
              <h3>Total Sales</h3>
              <h1>{totalSales}</h1>
            </div> */}
            <div style={{ width: "45%" }}>
              <h3>Total Users</h3>
              <h1>{totalUsers}</h1>
            </div>
            <div style={{ width: "45%" }}>
              <h3>Total Orders</h3>
              <h1>{totalOrders}</h1>
            </div>
          </div>
        </CardContent>
      </Card>
      <Card>
        {/* <CardContent>
          <h2>Sales, Visits and Orders Over Time</h2>
          <LineChart
            width={600}
            height={300}
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="sales"
              stroke="#8884d8"
              activeDot={{ r: 8 }}
            />
            <Line type="monotone" dataKey="visits" stroke="#82ca9d" />
            <Line type="monotone" dataKey="orders" stroke="#ffc658" />
          </LineChart>
        </CardContent> */}
      </Card>
    </div>
  );
};

export default Dashboard;
