import DateFormatter from "../utils/dateFormatterUtils";
import NumberFormatter from "../utils/numberFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const saleReportDataProvider = {
  getList: async (resource, params) => {
    const url = createUrl(`sale/${resource}`, params);

    const response = await mainDataProviderClient(url, RequestTypes.getAll);
    return {
      data: response.data.map((item) => ({
        id: item.id,
        name: item.product.name,
        sku: item.product.sku,
        category: item.category.name,
        brand: item.product.brand.source,
        createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
        quantity: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, item.quantity),
        quantitySold: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, item.quantitySold),
        totalSoldAmount: NumberFormatter.instance.formatNumber({ style: 'currency', currency: 'AED', maximumFractionDigits: 2, trailingZeroDisplay: "stripIfInteger" }, item.totalSoldAmount),
      })),
      total: response.total,
    };
  },
};

export default saleReportDataProvider;
