import DateFormatter from "../utils/dateFormatterUtils";
import NumberFormatter from "../utils/numberFormatterUtils";
import mainDataProviderClient, {
  RequestTypes,
} from "./common/mainDataProviderClient";
import { createUrl } from "../utils/urlUtils";

const inventoryReportDataProvider = {
  getList: async (resource, params) => {
    const url = createUrl(`inventory/${resource}`, params);

    const response = await mainDataProviderClient(url, RequestTypes.getAll);
    return {
      data: response.data.map((item) => ({
        id: item.id,
        name: item.product.name,
        sku: item.product.sku,
        createdAt: DateFormatter.instance.formatDateUTC(item.createdAt),
        updatedAt: DateFormatter.instance.formatDateUTC(item.updatedAt),
        category: item.category.name,
        brand: item.product.brand.source,
        price: item.product.price,
        quantity: NumberFormatter.instance.formatNumber({ notation: "compact", maximumFractionDigits: 2 }, item.quantity),
        price: NumberFormatter.instance.formatNumber({ style: 'currency', currency: 'AED', maximumFractionDigits: 2, trailingZeroDisplay: "stripIfInteger" }, item.product.price),
        unit: item.product.priceModel.unit,
      })),
      total: response.total,
    };
  },
};

export default inventoryReportDataProvider;
