import useChoices from "../../hooks/useChoices";
import { CreateView } from "../createView";
import { CommonForm } from "./CommonForm";

export const BannerForm = (props) => {
  const bannerTypes = useChoices("bannerTypes");

  return (
    <CreateView resource="banner">
      <CommonForm
        fields={["bannerTypes", "priority", "actionUrl", "image"]}
        choices={{
          bannerTypes: bannerTypes,
        }}
        includeSimpleForm={true}
        {...props}
      />
    </CreateView>
  );
};
