import { Show, TabbedShowLayout } from "react-admin";
import { CommonView } from "./CommonView";
import { Grid } from "@mui/material";
import { getFieldType } from "../../utils/fieldUtils";
import { CSVLink } from 'react-csv';
import userDataProvider from "../../dataProviders/userDataProvider";

export const UserView = ({ record }) => {

  // const exportAllOrdersAsCSV = async () => {
  //   try {
  //     const userData = await userDataProvider.getOne('users', { id: record.id });
  //     console.log(userData);
  //     const allOrders = userData.data.orders;

  //     const ordersForCSV = allOrders.map(order => ({
  //       Invoice_Number: order.invoiceNumber,
  //       Total_Amount: order.totalAmount,
  //     }));

  //     const csvReport = {
  //       filename: "user_orders.csv",
  //       data: ordersForCSV,
  //     };
  //   } catch (error) {
  //     console.error("Error exporting orders:", error);
  //   }
  // };

  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="General">
          <CommonView
            fields={[
              "userInfo.name",
              "userInfo.email",
              "userInfo.role",
              "userInfo.phoneNumber",
              "userInfo.createdAt",
              "userInfo.updatedAt",
              "userInfo.active",
              "userInfo.accountVerification",
            ]}
            isView={true}
            labels={[
              "Name",
              "Email",
              "Role",
              "Phone Number",
              "Created At",
              "Updated At",
              "Is Active",
              "Is Verified",
            ]}
          />
        </TabbedShowLayout.Tab>

        <TabbedShowLayout.Tab label="Addresses">
          {getFieldType("addresses", [], true)}
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Orders">
          {getFieldType("orders", [], true)}
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>)
};
