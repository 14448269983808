import { combineDataProviders } from "react-admin";
import categoryDataProvider from "../categoryDataProvider";
import productDataProvider from "../productDataProvider";
import subcategoryDataProvider from "../subcategoryDataProvider";
import userDataProvider from "../userDataProvider";
import brandDataProvider from "../brandDataProvider";
import priceUnitDataProvider from "../priceUnitDataProvider";

import mainDataProviderClient, { RequestTypes } from "./mainDataProviderClient";
import countryDataProvider from "../countryDataProvider";
import saleReportDataProvider from "../saleReportDataProvider";
import inventoryReportDataProvider from "../inventoryReportDataProvider";
import tagDataProvider from "../tagDataProvider";
import bannerDataProvider from "../bannerDataProvider";
import cartDataProvider from "../cartDataProvider";
import orderDataProvider from "../orderDataProvider";
import statisticsDataProvider from "../statisticsDataProvider";
import bannerTypesDataProvider from "../bannerTypesDataProvider";

export const commonDataProvider = {
  getList: async (resource, params) => {
    return await mainDataProviderClient(resource, RequestTypes.getAll);
  },
  getOne: async (resource, params) => {
    return await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.getById
    );
  },
  create: async (resource, params) => {
    return await mainDataProviderClient(`${resource}`, RequestTypes.create, {
      method: "POST",
      body: JSON.stringify(params.data),
    });
  },
  update: async (resource, params) => {
    return await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.update,
      {
        method: "PUT",
        body: JSON.stringify(params.data),
      }
    );
  },
  delete: async (resource, params) => {
    return await mainDataProviderClient(
      `${resource}/${params.id}`,
      RequestTypes.delete,
      {
        method: "DELETE",
      }
    );
  },
};

const mainDataProvider = combineDataProviders((resource) => {
  switch (resource) {
    case "category":
      return {
        ...commonDataProvider,
        ...categoryDataProvider,
      };
    case "subcategory":
      return {
        ...commonDataProvider,
        ...subcategoryDataProvider,
      };
    case "products":
      return {
        ...commonDataProvider,
        ...productDataProvider,
      };
    case "users":
      return {
        ...commonDataProvider,
        ...userDataProvider,
      };
    case "brand":
      return {
        ...commonDataProvider,
        ...brandDataProvider,
      };
    case "tags":
      return {
        ...commonDataProvider,
        ...tagDataProvider,
      };
    case "price":
      return {
        ...commonDataProvider,
        ...priceUnitDataProvider,
      };
    case "country":
      return {
        ...commonDataProvider,
        ...countryDataProvider,
      };
    case "inventory-report":
      return {
        ...commonDataProvider,
        ...inventoryReportDataProvider,
      };
    case "sales-report":
      return {
        ...commonDataProvider,
        ...saleReportDataProvider,
      };
    case "banner":
      return {
        ...commonDataProvider,
        ...bannerDataProvider,
      };
    case "bannerTypes":
      return {
        ...commonDataProvider,
        ...bannerTypesDataProvider,
      };
    case "orders":
      return {
        ...commonDataProvider,
        ...orderDataProvider,
      };
    case "cart":
      return cartDataProvider;
    case "statistics":
      return {
        ...commonDataProvider,
        ...statisticsDataProvider,
      };
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

export default mainDataProvider;
